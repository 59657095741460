<template>
<div>
    <a-modal title="导出展位图" v-model:visible="show" width="100%" @cancel="close"  wrapClassName="full-modal" :footer="null" :header="null">
      <div class="headbox">
        <div class="item">
          <span style="margin-right:4px;">背景图</span>
          <a-switch
              size="small"
              @change="bgSwitch"
              v-model:checked="options.bg"
              active-color="#247fce"
              inactive-color="#ccc">
          </a-switch>
        </div>
        <div class="item">
          <span style="margin-right:4px;">标尺</span>
          <a-switch
              size="small"
              @change="rulerSwitch"
              v-model:checked="options.ruler"
              active-color="#247fce"
              inactive-color="#ccc">
          </a-switch>
        </div>
        <!-- <div class="item">
          <span style="margin-right:4px;">网格</span>
          <a-switch
              size="small"
              @change="gridSwitch"
              v-model:checked="options.grid"
              active-color="#247fce"
              inactive-color="#ccc">
          </a-switch>
        </div> -->
        <div class="item">
          <span style="margin-right:4px;">标摊展位</span>
          <a-switch
              size="small"
              @change="btSwitch"
              v-model:checked="options.bt"
              active-color="#247fce"
              inactive-color="#ccc">
          </a-switch>
        </div>
        <div class="item">
          <span style="margin-right:4px;">豪标展位</span>
          <a-switch
              size="small"
              @change="haobiaoSwitch"
              v-model:checked="options.haobiao"
              active-color="#247fce"
              inactive-color="#ccc">
          </a-switch>
        </div>
        <div class="item">
          <span style="margin-right:4px;">交钥匙展位</span>
          <a-switch
              size="small"
              @change="keySwitch"
              v-model:checked="options.key"
              active-color="#247fce"
              inactive-color="#ccc">
          </a-switch>
        </div>
        <div class="item">
          <span style="margin-right:4px;">英文模板</span>
          <a-switch
              size="small"
              @change="enSwitch"
              v-model:checked="options.en"
              active-color="#247fce"
              inactive-color="#ccc">
          </a-switch>
        </div>
        <div class="item set_margin">
          <ColumnHeightOutlined />
          <span style="margin-right:4px;" @click="openMarginSetting()">边距设置</span>
        </div>
        <div class="item set_margin">
          <BorderLeftOutlined />
          <span style="margin-right:4px;"  @click="openBorderTypeSetting()">边框设置</span>
        </div>
        
        <div class="item download" @click="download">
          <DownloadOutlined />
          <span style="margin-right:4px;"> 下载</span>
        </div>
        
      </div>
      <div class="export-tips"><InfoCircleOutlined /> 边距和边框为算法识别，不一定准确，请检查无误后导出图纸！</div>
      <div class="export-content">
        <a id="link"></a>
        <canvas ref="can" id="exportBooth" :class="{'hasBg':options.bg}"></canvas>
      </div>
    </a-modal>

    <a-modal title="设置边距" v-model:visible="setMarginPopupIsShow" @cancel="handleCancel" width="700px" :footer="null">
        <a-form ref="formRef" :model="boothMarginData" :rules="rules" :label-col="{span: 4}" :wrapper-col="{span: 18}">
            <a-form-item label="上边距" name="display_content">
                <a-input v-model:value="boothMargin[0]" type="number" />
            </a-form-item>
            <a-form-item label="右边距" name="right">
                <a-input v-model:value="boothMargin[1]" type="number" />
            </a-form-item>
            <a-form-item label="下边距" name="remarks">
                <a-input v-model:value="boothMargin[2]" type="number" />
            </a-form-item>
            <a-form-item label="左边距" name="left">
                <a-input v-model:value="boothMargin[3]" type="number" />
            </a-form-item>
        </a-form>
        <div class="dialog-footer">
          <a-button type="primary" @click="setMargin()">确 定</a-button>
        </div>
    </a-modal>
    <a-modal title="设置边框类型" v-model:visible="setBorderTypeIsShow" @cancel="handleCancel" width="700px" :footer="null">
        <a-form ref="formRef" :model="boothMarginData" :rules="rules" :label-col="{span: 4}" :wrapper-col="{span: 18}">
            <a-form-item label="上边框" name="display_content">
                <a-radio-group name="radioGroup" v-model:value="boothBorder[0]">
                  <a-radio :value="0">实线</a-radio>
                  <a-radio :value="1">虚线</a-radio>
                </a-radio-group>
            </a-form-item>
            <a-form-item label="右边框" name="right">
              <a-radio-group name="radioGroup" v-model:value="boothBorder[1]">
                  <a-radio :value="0">实线</a-radio>
                  <a-radio :value="1">虚线</a-radio>
                </a-radio-group>
            </a-form-item>
            <a-form-item label="下边框" name="remarks">
              <a-radio-group name="radioGroup" v-model:value="boothBorder[2]">
                  <a-radio :value="0">实线</a-radio>
                  <a-radio :value="1">虚线</a-radio>
                </a-radio-group>
            </a-form-item>
            <a-form-item label="左边框" name="left">
              <a-radio-group name="radioGroup" v-model:value="boothBorder[3]">
                  <a-radio :value="0">实线</a-radio>
                  <a-radio :value="1">虚线</a-radio>
                </a-radio-group>
            </a-form-item>
        </a-form>
        <div class="dialog-footer">
          <a-button type="primary" @click="setBorder()">确 定</a-button>
        </div>
    </a-modal>
    
</div>
</template>

<script>
//   import { Loading } from 'element-ui';
import {useGetMapStyle,loadImage,getBackgroundByIndex,getAreaImgByIndex} from '@/use/diy-use.js'
import store from '@/store'
import {message} from 'ant-design-vue';
import {BorderLeftOutlined,ColumnHeightOutlined,DownloadOutlined,InfoCircleOutlined} from '@ant-design/icons-vue';
import moment from 'moment';


  export default {
    components:{BorderLeftOutlined,ColumnHeightOutlined,DownloadOutlined,InfoCircleOutlined},
    props:{
    //   mapStyle:{type:Object, default:{}},
      exportVisible:{type:Boolean, default:false}
    },
    data(){
      return {
        diySet:false,
        setMarginPopupIsShow:false,
        setBorderTypeIsShow:false,
        options:{
          en:false,
          bt:false,
          haobiao:false,
          key:false,
          bg:true,
          status:false,
          ruler:true,
          grid:false
        },
        boothMargin:[0,0,0,0],
        boothBorder:[1,1,1,1],
        canvas: null,
        // cpadding:[350,240,120,240],
        cpadding:[640,360,120,360],
        // cpadding:[0,0,0,0],
        ctx:null,
        innerTop:0,
        innerLeft:0,
        innerWidth:0, //去掉左右边距，去掉背景图内边距，绘图区域显示像素，(包含展馆四角内边距)
        innerHeight:0,
        height:'',
        show : false,
        mapStyle:{},
        rectPos:{}, //当前展位在a4纸中的坐标
      }
    },
    computed:{
      lists(){return this.$store.state.lists},
      selectCategory(){return this.$store.state.selectCategory},
      isAdmin(){return (store.state.user.role_id===1||store.state.user.role_id===2)},
      user(){return (store.state.user)},
    },
    mounted(){
      // window.onresize = () => {
      //   this.show && this.initCalc();
      // }
      // this.mapStyle = useGetMapStyle(this.$store.state.selectCategory);
    },
    methods:{
      initCalc(){
          this.mapStyle = useGetMapStyle(this.$store.state.selectCategory);
          if(!this.canvas){
            this.canvas = document.getElementById("exportBooth");
            this.ctx = this.canvas.getContext('2d');
          }
          const {canvas} = this;
          const winW = window.innerWidth;
          const winH = window.innerHeight;
          // const widthA4 = 3508; //a4纸长边分辨率
          let widthA4 = 2480;
          let heightA4 = 3768
          if(store.state.selectIndex*1>=5){
            heightA4 = 3868
          }
          if(this.options.en&&store.state.selectIndex*1>=5){
            heightA4 = 3918
          }
          if(this.options.en&&store.state.selectIndex*1<5){
            heightA4 = 3818
          }

          const cp = this.cpadding;

          // let {width,height} = this.mapStyle.outer;
          let width,height;
          //带背景图片
          if(this.options.bg){
            width = this.mapStyle.outer.width;
            height = this.mapStyle.outer.height;
          }else{
            width = this.mapStyle.inner.width;
            height = this.mapStyle.inner.height;
          }
          const hwRadio = height.replace('px','')/width.replace('px','');

          canvas.width = widthA4+cp[1]+cp[3];
          // canvas.height = (height.replace('px','')/width.replace('px','')*widthA4)+cp[0]+cp[2];
          canvas.height = heightA4+cp[0]+cp[2];
          // let radio = widthA4/this.mapStyle.outer.width.replace("px",'');
          let radio = widthA4/width.replace("px",'');


          if(this.options.bg){
            const {top,left,width,height} = this.mapStyle.inner;
            this.innerTop = top.replace("px",'')*radio;
            this.innerLeft = left.replace("px",'')*radio;
            this.innerWidth = width.replace("px",'')*radio; 
            this.innerHeight = height.replace("px",'')*radio; 
          }else{
            this.innerTop = 0;
            this.innerLeft = 0;
            this.innerWidth = canvas.width-cp[1]-cp[3]; 
            this.innerHeight = canvas.height-cp[0]-cp[2]; 
          }

          //初步判断是否为标摊
          console.log(store.state.buyPopupData.width)
          if(!this.diySet&&store.state.buyPopupData.width/1000*store.state.buyPopupData.height/1000<=18){
            this.options.bt=true;
            // this.options.en = false;
          }
          //boothBorder:[store.state.buyPopupData.top_line,store.state.buyPopupData.right_line,store.state.buyPopupData.bottom_line,store.state.buyPopupData.left_line],

          // console.log("store.state.buyPopupData:",store.state.buyPopupData)

          this.draw(); //绘制展位
      },
      //获取展位之间的间距  不成熟，没有使用
      getDist(){
        // const {left_margin,right_margin} = this.selectCategory;
        // let ratio = (1500-right_margin-left_margin)/this.selectCategory.width;
        const {canvas,ctx} = this;
        const cp = this.cpadding;
        let radio =  this.innerWidth/this.selectCategory.width;  //真实长度和像素比例
        console.log(this.innerWidth,this.selectCategory.width)
        let distW = [];
        let distH = [];
        console.log(this.selectCategory)
        this.lists.forEach((item,index)=>{
          // console.log(item)
          let itemRight = item.left+item.width;
          let itemTop = item.top;
          let itemLeft = item.left;
          let itemBottom = itemTop+item.height;
          
          let minDist = null;
          let minHDist = null;
          this.lists.forEach((item2,index2)=>{
          //检测水平位置展位间距
          if(Math.abs(itemTop-item2.top)<10/radio && item2.left >= itemRight){
            minDist === null && (minDist = item2.left - itemRight);
              if(item2.left - itemRight<minDist){
                minDist = item2.left - itemRight;
              }
            }
            //检测垂直方向展位距离
            if(Math.abs(itemLeft-item2.left)<30/radio && item2.top >= itemBottom){
              minHDist === null && (minHDist = item2.top - itemBottom);

              if(item2.top - itemBottom<minHDist){
                minHDist = item2.top - itemBottom;
              }
            }
          })

          if(minDist!==null&&minDist<15000&&minDist>0){
            // distW.push({top:itemTop*radio+'px',left:itemRight*radio+'px',width:minDist*radio+'px',w:minDist});
            let x,y,w;
            //画标尺
            let offset = 13;
            x=itemRight*radio+this.innerLeft+cp[3];
            y=itemTop*radio+this.innerTop+cp[0]+item.height*radio;
            if(itemTop>this.selectCategory.height/2){
              //下半部分展位标尺
              y=itemTop*radio+this.innerTop+cp[0];
              offset = -13;
            }

            w=minDist*radio
            distW.push({x,y,w});

            if(this.options.grid){
              offset=1000*radio;
            }
            ctx.lineWidth = 1;
            ctx.strokeStyle = '#666';
            
            ctx.save(); 
            ctx.translate(0.5,0.5);
            ctx.beginPath();
            ctx.moveTo(x,y+offset);
            ctx.lineTo(x+w, y+offset);
            ctx.stroke();

            //画文字
            ctx.font = "bold 15px Arial";
            ctx.fillStyle = "#333";
            ctx.fillStyle = "#666";
            ctx.textAlign = "center";
            ctx.fillText(minDist,x+w/2,y+offset+13);

          }
          if(minHDist!==null&&minHDist<15000&&minDist>0){
            // distH.push({top:itemBottom*radio+'px',left:itemLeft*radio+'px',height:minHDist*radio+'px',h:minHDist});
            distH.push({y:itemBottom,x:itemLeft,height:minHDist});
          }
        })
        // console.log(distW,distH)
      },
      async draw(){
        const {canvas,ctx} = this;
        // const {picture,width,height} = this.selectCategory;
        const {picture,hall_number} = this.selectCategory;
        // console.log(this.selectCategory)
        // console.log(this.mapStyle)

        const cp = this.cpadding;
        ctx.fillStyle="#ffffff";
        ctx.fillRect(0,0,canvas.width, canvas.height);
        let width,height;
        //带背景图片和不带背景图片，主要用于计算长宽比例
        if(this.options.bg){
          width = this.mapStyle.outer.width;
          height = this.mapStyle.outer.height;
        }else{
          width = this.mapStyle.inner.width;
          height = this.mapStyle.inner.height;
        }

        if(this.options.bg&&picture!=="http://sale2025admin.uniceramics.com.cn/storage/showroom_category/"){
          //因为服务器有跨域问题，此处从本地设置图片
          let bgurl = getBackgroundByIndex(hall_number*1);
          const widthA4 = 2480;
          let bgImgObj = await loadImage(bgurl);
         
          ctx.drawImage(bgImgObj, 0, 0, bgImgObj.width, bgImgObj.height, cp[3], cp[0], canvas.width-cp[1]-cp[3],(height.replace('px','')/width.replace('px','')*widthA4));
          this.options.grid && this.drawGrid();
          this.options.ruler && this.drawPaddingRuler();
          this.drawRect();
          this.drawTitle();
          //画放大框
          this.drawZoom();
        }else{
          this.options.bg = false;
          this.options.grid && this.drawGrid();
          ctx.beginPath();
          ctx.lineWidth="3";
          ctx.strokeStyle="#000000";
          ctx.rect(cp[3], cp[0], canvas.width-cp[1]-cp[3], (canvas.width-cp[1]-cp[3])*(height.replace('px','')/width.replace('px','')));
          ctx.stroke();
          this.drawTitle();
          this.drawRect();
          this.options.ruler && this.drawPaddingRuler();
          //画放大框
          this.drawZoom();
        }
      },
      async drawTitle(){
        const {canvas,ctx} = this;
        const {name, width, height,picture,hall_number, name_en } = this.selectCategory;
        const cp = this.cpadding;
        // console.log(this.selectCategory);
        const leftRight = this.options.bg?cp[3]+80:cp[3]; //文字左右边距
        const area = Math.floor(width*height/1000000*10)/10+"㎡";

        let titleTop = 190;
        ctx.beginPath();
        ctx.fillStyle="#000";
        ctx.font = "50px Arial"
        ctx.textAlign = 'left'

        if(!this.options.bt&&!this.options.haobiao&&!this.options.key&&!this.options.en){
          ctx.fillText("附件一", 200, titleTop+20);
          ctx.fillText("展位示意图：", 200, titleTop+100);
        }
        if(!this.options.bt&&!this.options.haobiao&&!this.options.key&&this.options.en){
          ctx.fillText("Appendix 1 ", 200, titleTop+20);
          ctx.fillText("Floor Plan：", 200, titleTop+100);
        }
        if(this.options.bt&&!this.options.en){
          ctx.fillText("附件一", 200, titleTop+20);
          ctx.fillText("展位（标摊）位置示意图：", 200, titleTop+100);
        }
        if(this.options.bt&&this.options.en){
          ctx.fillText("Appendix 1 ", 200, titleTop+20);
          ctx.fillText("Floor Plan：", 200, titleTop+100);
        }
        if(this.options.haobiao){
          ctx.fillText("附件一", 200, titleTop+20);
          ctx.fillText("展位（豪标）位置示意图：", 200, titleTop+100);
        }
        if(this.options.key){
          ctx.fillText("附件一", 200, titleTop+20);
          ctx.fillText("展位（交钥匙展位）位置示意图：", 200, titleTop+100);
        }


        //画logo
        let logoImgObj;
        if(this.options.en){
          logoImgObj = await loadImage(require('@/assets/logo_text_en.png'));
        }else{
          logoImgObj = await loadImage(require('@/assets/logo_text.png'));
        }
        
        ctx.drawImage(logoImgObj, 295, titleTop+225, 577, 144);
        //画线条
        ctx.moveTo(920,titleTop+360);
        ctx.lineTo(2674,titleTop+360);
        ctx.lineWidth = 4;
        ctx.strokeStyle = "#111";
        ctx.stroke();
        
        // 画标展馆题
        if(!this.options.en){
          ctx.fillStyle="#fe0000";
          ctx.font = "64px Arial"
          ctx.textAlign = 'center'
          const nameWidth = ctx.measureText(name);
          ctx.fillRect(924, titleTop+230, nameWidth.width+50, 64+50);
          ctx.fillStyle="#fff";
          ctx.textBaseline = 'top';
          ctx.fillText(name,924+nameWidth.width/2+25,titleTop+230+34);
        }else{
          ctx.fillStyle="#fe0000";
          ctx.font = "45.5px Arial"
          ctx.textAlign = 'center'
          const nameWidth = ctx.measureText(name_en);
          ctx.fillRect(924, titleTop+230, nameWidth.width+50, 64+50);
          ctx.fillStyle="#fff";
          ctx.textBaseline = 'top';
          ctx.fillText(name_en,924+nameWidth.width/2+25,titleTop+230+34);
        }

        //画展馆方位图
        // let areaImgObj = await loadImage(getAreaImgByIndex(store.state.selectIndex*1));
        let areaImgObj = await loadImage(getAreaImgByIndex(hall_number*1));
        ctx.drawImage(areaImgObj, 2717, titleTop+170, 194, 193);

        //画水印
        this.drawShuiyin();

      },
      // 检测两条线段是否有重合，在x轴或y轴, 当检查横向线段时候，x1，x2，代表线段横向两端x坐标，当检查纵向线段时候，x1，x2代表纵向线段两端的y坐标
      checkLineCross(line1,line2){
        if(line2.x2>line1.x2 && line2.x1<=line1.x1){
          return true;
        }
        if(line2.x2<=line1.x2 && line2.x1>=line1.x1){
          return true;
        }
        if(line2.x2<=line1.x2 && line2.x2>line1.x1){
          return true;
        }
        if(line2.x1>=line1.x1 && line1.x2>line2.x1){
          return true;
        }
      },
      drawRect(){
         const {canvas,ctx} = this;
         const cp = this.cpadding;
         const radio =  this.innerWidth/this.selectCategory.width;  //真实长度和像素比例
         let customer_brand = localStorage.getItem('customer_brand')||'';

         //用来存放不同方向的矩形
         let topRect = [];
         let rightRect = [];
         let bottomRect = [];
         let leftRect = [];

         this.lists.forEach((item)=>{
          const {width,height,top,left,status,title} = item;

          //检测展位边距
          //判断上边距
          // 上方矩形，上方矩形的top+height必须大于等于目标矩形的top && 上方矩形在垂直方向尽可能多的重叠， 然后取和目标矩形最近的矩形，算上边距
          let targetRect = store.state.buyPopupData;
          // if(item.id!==targetRect.id&&top+height<=targetRect.top&&Math.abs(left-targetRect.left)<=1100){
          if(item.id!==targetRect.id&&top+height<=targetRect.top && this.checkLineCross({x1:left,x2:left+width},{x1:targetRect.left,x2:targetRect.left+targetRect.width})){

            topRect.push(item)
          }
          // if(item.id!==targetRect.id&&left>=targetRect.left+targetRect.width&&Math.abs(top-targetRect.top)<=1100){
          if(item.id!==targetRect.id&&left>=targetRect.left+targetRect.width&& this.checkLineCross({x1:top,x2:top+height},{x1:targetRect.top,x2:targetRect.top+targetRect.height})){

            rightRect.push(item)
          }
          // if(item.id!==targetRect.id&&top>=targetRect.top+targetRect.height&&Math.abs(left-targetRect.left)<=1100){
          if(item.id!==targetRect.id&&top>=targetRect.top+targetRect.height&& this.checkLineCross({x1:left,x2:left+width},{x1:targetRect.left,x2:targetRect.left+targetRect.width})){
            bottomRect.push(item)
          }
          if(item.id!==targetRect.id&&targetRect.left>=(left+width)&& this.checkLineCross({x1:top,x2:top+height},{x1:targetRect.top,x2:targetRect.top+targetRect.height})){
            leftRect.push(item)
          }


          ctx.beginPath();
          ctx.strokeStyle="#ed1f24";
          let rleft = left*radio+this.innerLeft+cp[3];
          let rtop = top*radio+this.innerTop+cp[0];
          let rheight = height*radio;
          let rwidth = width*radio;
          ctx.rect(rleft, rtop, rwidth, rheight);
          ctx.stroke();
          
          if(this.selectCategory.code!==11&&this.selectCategory.code!==12){
            this.options.ruler && this.drawRuler(item);
          }

          ctx.lineWidth="3";
          ctx.fillStyle="#fff";
          ctx.fillRect(rleft, rtop, rwidth, rheight);

          //当前展位背景色设置为红色
          if(item.id===store.state.buyPopupData.id){
            ctx.fillStyle="#ffadad";
            ctx.fillRect(rleft, rtop, rwidth, rheight);
            console.log(rleft, rtop, rwidth, rheight)
            console.log(item);
            this.rectPos = {rleft, rtop, rwidth, rheight}
          }
          
          //已经完成
          if(this.options.status&&item.status===2){
            ctx.fillStyle="#ffadad";
            ctx.fillRect(rleft, rtop, rwidth, rheight);
          }
          //锁定中
          if(this.options.status&&item.status===3){
            ctx.fillStyle="#dddddd";
            ctx.fillRect(rleft, rtop, rwidth, rheight);
          }


          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          //长条形展位
          if(height<=4000&&width>20000){
            ctx.font = "28px bold Arial";
            ctx.fillStyle = "#000";
            let text = title+ "　" +width/1000+"×"+height/1000+"="+width*height/1000000;
            if(item.id===store.state.buyPopupData.id){
              console.log("长条形展位")
              let maxFontSize = Math.floor(rwidth/28)-text.length;
              let company = item.order_form.customer_brand
              let subtext = company.length<maxFontSize?company:company.substr(0,maxFontSize-1)+'...';
              text = title+ "　" + subtext + "　" + width/1000+"×"+height/1000+"="+width*height/1000000;
              ctx.fillText(text, rleft+rwidth/2, rtop+rheight/2);
            }else{
              text = title;
              ctx.fillText(text, rleft+rwidth/2, rtop+rheight/2);
            }
            
            return false;
          }

          //长条形展位  竖向
          if(width<=4000&&height>20000){
            ctx.font = "bold 20px Arial";
            ctx.fillStyle = "#000";
            let text = title+ "　" +width/1000+"×"+height/1000+"="+width*height/1000000;
            if(item.id===store.state.buyPopupData.id){
              console.log("长条形展位  竖向")
              let maxFontSize = Math.floor(rheight/20)-text.length;
              let company = customer_brand
              let subtext = company.length<maxFontSize?company:company.substr(0,maxFontSize-1)+'...';
              text = title+ "　" + subtext + "　" + width/1000+"×"+height/1000+"="+width*height/1000000;
              ctx.save();
              ctx.translate(rleft+rwidth/2,rtop+rheight/2);
              ctx.rotate(Math.PI/180*90);
              ctx.textBaseline = 'middle';
              ctx.fillText(text,0,0);
              ctx.restore();
              // y+=ctx.measureText(str).width+letterSpacing;
              // ctx.rotate(Math.PI/180*90);
              ctx.restore();
            }else{
              text = title;
              ctx.save();
              ctx.translate(rleft+rwidth/2,rtop+rheight/2);
              ctx.rotate(Math.PI/180*90);
              ctx.textBaseline = 'middle';
              ctx.fillText(text,0,0);
              ctx.restore();
              ctx.restore();
            }
            return false;
          }

          //宽高都大于6m的展位
          if(height>6000&&width>6000){
            let offset = 20;
            if(item.id===store.state.buyPopupData.id){
              console.log("宽高都大于6m的展位")
              offset = 40
              ctx.font = "bold 26px Arial";
              ctx.fillStyle = "#333";
              let text = this.splitText(customer_brand, rwidth, 26);
              ctx.fillText(text, rleft+rwidth/2, rtop+rheight/2);
              ctx.font = "bold 30px Arial";
              ctx.fillStyle = "#000";
              ctx.fillText(title, rleft+rwidth/2, rtop+rheight/2-offset+2);
              ctx.font = "bold 22px Arial";
              ctx.fillStyle = "#333";
              ctx.fillText(width/1000+"×"+height/1000+"="+width*height/1000000, rleft+rwidth/2, rtop+rheight/2+offset);
            }else{
              ctx.font = "bold 30px Arial";
              ctx.fillStyle = "#000";
              ctx.fillText(title, rleft+rwidth/2, rtop+rheight/2);
            }
            return false;
          }

          //宽度为3米，高度小于20m的小展位
          if(height<20000&&height>5000&&width===3000){
            let offset = 8;
            if(item.id===store.state.buyPopupData.id){
              console.log("宽度为3米，高度小于20m的小展位大于5m")
              offset = 20
              ctx.font = "bold 12px Arial";
              ctx.fillStyle = "#333";
              let text = this.splitText(customer_brand, rwidth, 12);
              ctx.fillText(text, rleft+rwidth/2, rtop+rheight/2);
              ctx.font = "bold 18px Arial";
              ctx.fillStyle = "#000";
              ctx.fillText(title, rleft+rwidth/2, rtop+rheight/2-offset);
              ctx.font = " bold 12px Arial";
              ctx.fillStyle = "#333";
              ctx.fillText(width/1000+"×"+height/1000+"="+width*height/1000000, rleft+rwidth/2, rtop+rheight/2+offset+4);
            }else{
              ctx.font = "bold 18px Arial";
              ctx.fillStyle = "#000";
              ctx.fillText(title, rleft+rwidth/2, rtop+rheight/2);
            }
            
            return false;
          }

          // 高度或宽度小于6m的中小型展位
          if(height<=6000||width<=6000){
            let offset = 10;
            if(item.id===store.state.buyPopupData.id){
              console.log("高度或宽度小于6m的中小型展位")
              ctx.font = "bold 17px Arial";
              if(this.selectCategory.width>140000){
                ctx.font = "bold 14px Arial";
              }
              ctx.fillStyle = "#000";
              ctx.fillText(title, rleft+rwidth/2, rtop+rheight/2-offset);
              ctx.font = "bold 14px Arial";
              ctx.fillStyle = "#333";
              ctx.fillText(width/1000+"×"+height/1000+"="+width*height/1000000, rleft+rwidth/2, rtop+rheight/2+offset+5);
            }else{
              ctx.font = "bold 17px Arial";
              if(this.selectCategory.width>140000){
                ctx.font = "bold 14px Arial";
              }
              ctx.fillStyle = "#000";
              ctx.fillText(title, rleft+rwidth/2, rtop+rheight/2);
            }
           
            return false;
          }
         })

         if(this.diySet){
          return false;
         }
        //  // 上边距判断
        //   if(store.state.buyPopupData.top-this.selectCategory.top_padding<1100){
        //       this.boothMargin[0] = store.state.buyPopupData.top
        //   }
        //   //  右边距判断
        //   if(this.selectCategory.width-(store.state.buyPopupData.left+store.state.buyPopupData.width)<this.selectCategory.right_padding+1100){
        //       this.boothMargin[1] = this.selectCategory.width-(store.state.buyPopupData.left+store.state.buyPopupData.width)
        //   }
        //   // 下边距判断
        //   if(this.selectCategory.height-(store.state.buyPopupData.top+store.state.buyPopupData.height)<this.selectCategory.bottom_padding+1100){
        //       this.boothMargin[2] = this.selectCategory.height-(store.state.buyPopupData.top+store.state.buyPopupData.height)
        //   }
        //   // 左侧边距判断
        //   if(store.state.buyPopupData.left-this.selectCategory.left_padding<1100){
        //       this.boothMargin[3] = store.state.buyPopupData.left
        //   }build

          topRect.sort((a,b)=>{
            return (b.top+b.height)-(a.top+a.height)
          })
          if(topRect.length>0){
            this.boothMargin[0] = store.state.buyPopupData.top - (topRect[0].top+topRect[0].height)
          }else{
            this.boothMargin[0] = store.state.buyPopupData.top
          }

          bottomRect.sort((a,b)=>{
            return (a.top)-(b.top)
          })
          if(bottomRect.length>0){
            this.boothMargin[2] = bottomRect[0].top - (store.state.buyPopupData.top+store.state.buyPopupData.height)
          }else{
            this.boothMargin[2] = this.selectCategory.height-(store.state.buyPopupData.top+store.state.buyPopupData.height)
          }

          rightRect.sort((a,b)=>{
            return (a.left)-(b.left)
          })
          if(rightRect.length>0){
            this.boothMargin[1] = rightRect[0].left - (store.state.buyPopupData.left+store.state.buyPopupData.width)
          }else{
            this.boothMargin[1] = this.selectCategory.width-(store.state.buyPopupData.left+store.state.buyPopupData.width)
          }

          leftRect.sort((a,b)=>{
            return (b.left+b.width)-(a.left+a.width)
          })
          if(leftRect.length>0){
            this.boothMargin[3] = (store.state.buyPopupData.left)-(leftRect[0].left +leftRect[0].width)
          }else{
            this.boothMargin[3] = store.state.buyPopupData.left
          }
          //边距大于15米不显示
          for(let i=0;i<4;i++){
            console.log("i:",i,this.boothMargin[i])
            if(this.boothMargin[i]>15000){
              this.boothMargin[i] = 0;
            }
          }

          console.log(this.selectCategory)
          console.log(store.state.buyPopupData)

      },
      drawRuler(rect){
        const {canvas,ctx} = this;
        const {width,height,top,left,status,title} = rect;
        const radio =  this.innerWidth/this.selectCategory.width;  //真实长度和像素比例
        const cp = this.cpadding;

        let rleft = Math.round(left*radio+this.innerLeft+cp[3]);
        let rtop = Math.round(top*radio+this.innerTop+cp[0]);
        let rheight = Math.round(height*radio);
        let rwidth = Math.round(width*radio);

        
        ctx.lineWidth = 1;
        ctx.strokeStyle = '#666';
        ctx.font = "bold 15px Arial";
        ctx.fillStyle = "#333";
        ctx.textBaseline = 'middle';
       
        //画左侧标尺
        // console.log(rleft-20,rtop,rleft,rtop+rheight) //矩形左上角，右下角顶点
        let offset = 14;
        if(this.options.grid){
          offset=1000*radio;
        }
        if(rect.height>3000&&this.checkCross({left:rleft-21, top:rtop, width:20, height: rheight})&&rect.left>3000){
          ctx.save(); 
          ctx.translate(0.5,0.5);
          ctx.beginPath();
          ctx.moveTo(rleft-offset,rtop);
          ctx.lineTo(rleft-offset,rtop+rheight);
          ctx.moveTo(rleft-offset-5, rtop);
          ctx.lineTo(rleft-5, rtop);
          ctx.moveTo(rleft-offset-5, rtop+rheight);
          ctx.lineTo(rleft-5, rtop+rheight);
          ctx.stroke();
          ctx.restore();
          // 画箭头
          ctx.moveTo(rleft-offset-4,rtop-4);
          ctx.lineTo(rleft-offset+4,rtop+4);
          ctx.moveTo(rleft-offset-4,rtop+rheight-4);
          ctx.lineTo(rleft-offset+4,rtop+rheight+4);
          ctx.stroke();
          //画文字
          ctx.save();
          ctx.translate(rleft-offset-12,rtop+rheight/2);
          ctx.rotate(Math.PI/2);
          ctx.fillText(height,0,0);
          ctx.restore();
        }
        
        if(rect.width>3000&&top*radio<this.innerHeight/2 && this.checkCross({left:rleft, top:rtop+rheight+5, width:rwidth, height: 20})){
          // console.log(title+"----上半部分展位");
          //画下面侧标尺
          ctx.lineWidth = 1;
          ctx.save();
          ctx.translate(0.5,0.5);
          ctx.beginPath();
          ctx.moveTo(rleft,rtop+rheight+offset);
          ctx.lineTo(rleft+rwidth, rtop+rheight+offset);
          ctx.moveTo(rleft,rtop+rheight+5);
          ctx.lineTo(rleft, rtop+rheight+offset+5);
          ctx.moveTo(rleft+rwidth,rtop+rheight+5);
          ctx.lineTo(rleft+rwidth, rtop+rheight+offset+5);
          ctx.stroke();
  
          // 画箭头
          ctx.moveTo(rleft+4,rtop+rheight+offset-4);
          ctx.lineTo(rleft-4,rtop+rheight+offset+4);
          ctx.moveTo(rleft+rwidth+4, rtop+rheight+offset-4);
          ctx.lineTo(rleft+rwidth-4, rtop+rheight+offset+4);
          ctx.stroke();
          ctx.restore();
          //画文字
          ctx.fillText(width,rleft+rwidth/2,rtop+rheight+offset+13);
        }

        if(rect.width>3000&&top*radio>this.innerHeight/2 && this.checkCross({left:rleft, top:rtop-offset-10, width:rwidth, height: 20})){
          // console.log(title+"----下半部分展位");
          //画下面侧标尺
          ctx.lineWidth = 1;
          ctx.save();
          ctx.translate(0.5,0.5);
          ctx.beginPath();
          ctx.moveTo(rleft,rtop-offset);
          ctx.lineTo(rleft+rwidth, rtop-offset);
          ctx.moveTo(rleft,rtop-5);
          ctx.lineTo(rleft, rtop-offset-5);
          ctx.moveTo(rleft+rwidth,rtop-5);
          ctx.lineTo(rleft+rwidth, rtop-offset-5);
          ctx.stroke();
          ctx.restore();
          // 画箭头
          ctx.moveTo(rleft+4,rtop-offset-4);
          ctx.lineTo(rleft-4,rtop-offset+4);
          ctx.moveTo(rleft+rwidth+4, rtop-offset-4);
          ctx.lineTo(rleft+rwidth-4, rtop-offset+4);
          ctx.stroke();
          //画文字
          ctx.fillText(width,rleft+rwidth/2,rtop-offset-10);
        }

      },
      checkCross(rect){
        let bool = true;
        const radio =  this.innerWidth/this.selectCategory.width;  //真实长度和像素比例
        const cp = this.cpadding;

        for(let i=0; i<this.lists.length; i++){
          const {width,height,top,left,status,title} = this.lists[i];
          let r = {
            left:left*radio+this.innerLeft+cp[3],
            top:top*radio+this.innerTop+cp[0],
            height:height*radio,
            width:width*radio
          }
          if(this.isCross(rect,r)){
            bool = false;
            break;
          }
        }
        return bool;
      },
      isCross(rect1,rect2){
        let lux = Math.max(rect1.left, rect2.left);
        let luy = Math.max(rect1.top, rect2.top);
        let rdx = Math.min(rect1.left+rect1.width, rect2.left+rect2.width);
        let rdy = Math.min(rect1.top+rect1.height, rect2.top+rect2.height);
        // return (lux<rdx && luy<rdy);
        //此处允许有1个像素误差，某些展位顶点有重合，不认为展位有重合
        return (lux+1<rdx && luy+1<rdy); 
      },
      drawPaddingRuler(){
        const {canvas,ctx} = this;
        const radio =  this.innerWidth/this.selectCategory.width;  //真实长度和像素比例
        const cp = this.cpadding;
        const org = {x:cp[3]+this.innerLeft, y:cp[0]+this.innerTop};

        //根据展位内边距计算边距标尺
        const sumWidth = this.selectCategory.width*radio;
        const sumHeight = this.selectCategory.height*radio;
        const top = this.selectCategory.top_padding*radio;
        const bottom = this.selectCategory.bottom_padding*radio;
        const left = this.selectCategory.left_padding*radio;
        const right = this.selectCategory.right_padding*radio;
        const width = sumWidth - left -right;
        const height = sumHeight -top -bottom;

        if(top===0&&left===0){
          return false;
        }

        ctx.beginPath();
        ctx.lineWidth = 1;
        ctx.font = "bold 16px Arial";
        ctx.fillStyle = "#333";
        ctx.strokeStyle = '#666';
        ctx.textBaseline = 'top';
        ctx.textAlign = "center";
        // ctx.setLineDash([3]);
        ctx.translate(0.5,0.5);
        

        /* 横向 */

        //左上
        ctx.moveTo(org.x, org.y+top);
        ctx.lineTo(org.x+left, org.y+top);
        ctx.fillText(this.selectCategory.left_padding, org.x+left/2, org.y+top+5)
        // 画箭头
        ctx.moveTo(org.x+4, org.y+top-4);
        ctx.lineTo(org.x-4, org.y+top+4);
        ctx.moveTo(org.x+left+4, org.y+top-4);
        ctx.lineTo(org.x+left-4, org.y+top+4);
        ctx.stroke();

        //左下
        ctx.moveTo(org.x, org.y+sumHeight-bottom);
        ctx.lineTo(org.x+left, org.y+sumHeight-bottom);
        ctx.fillText(this.selectCategory.left_padding, org.x+left/2, org.y+sumHeight-bottom-23)
        // 画箭头
        ctx.moveTo(org.x+4, org.y+sumHeight-bottom-4);
        ctx.lineTo(org.x-4, org.y+sumHeight-bottom+4);
        ctx.moveTo(org.x+left+4, org.y+sumHeight-bottom-4);
        ctx.lineTo(org.x+left-4, org.y+sumHeight-bottom+4);
        ctx.stroke();

        // 右上
        ctx.moveTo(org.x+sumWidth-right, org.y+top);
        ctx.lineTo(org.x+sumWidth, org.y+top);
        ctx.fillText(this.selectCategory.right_padding, org.x+sumWidth-right/2, org.y+top+5)
        // 画箭头
        ctx.moveTo(org.x+sumWidth-right+4, org.y+top-4);
        ctx.lineTo(org.x+sumWidth-right-4, org.y+top+4);
        ctx.moveTo(org.x+sumWidth+4, org.y+top-4);
        ctx.lineTo(org.x+sumWidth-4, org.y+top+4);
        ctx.stroke();

        // 右下
        ctx.moveTo(org.x+sumWidth-right, org.y+sumHeight-bottom);
        ctx.lineTo(org.x+sumWidth, org.y+sumHeight-bottom);
        ctx.fillText(this.selectCategory.right_padding, org.x+sumWidth-right/2, org.y+sumHeight-bottom-20)
        // 画箭头
        ctx.moveTo(org.x+sumWidth-right+4, org.y+sumHeight-bottom-4);
        ctx.lineTo(org.x+sumWidth-right-4, org.y+sumHeight-bottom+4);
        ctx.moveTo(org.x+sumWidth+4, org.y+sumHeight-bottom-4);
        ctx.lineTo(org.x+sumWidth-4, org.y+sumHeight-bottom+4);
        ctx.stroke();

        /* 纵向 */
        //左上
        ctx.moveTo(org.x+left, org.y);
        ctx.lineTo(org.x+left, org.y+top);
        ctx.save();
        ctx.translate(org.x+left+20, org.y+top/2);
        ctx.rotate(Math.PI/2);
        ctx.fillText(this.selectCategory.top_padding,0,0);
        ctx.restore();
        // 画箭头
        ctx.moveTo(org.x+left+4,org.y-4);
        ctx.lineTo(org.x+left-4, org.y+4);
        ctx.stroke();

        //左下
        ctx.moveTo(org.x+left, org.y+sumHeight-bottom);
        ctx.lineTo(org.x+left, org.y+sumHeight);
        ctx.save();
        ctx.translate(org.x+left+23, org.y+sumHeight-bottom/2);
        ctx.rotate(Math.PI/2);
        ctx.fillText(this.selectCategory.top_padding,0,0);
        ctx.restore();
        // 画箭头
        ctx.moveTo(org.x+left+4,org.y+sumHeight-4);
        ctx.lineTo(org.x+left-4, org.y+sumHeight+4);
        ctx.stroke();

        //右上
        ctx.moveTo(org.x+sumWidth-right, org.y);
        ctx.lineTo(org.x+sumWidth-right, org.y+top);
        ctx.save();
        ctx.translate(org.x+sumWidth-right-5, org.y+top/2);
        ctx.rotate(Math.PI/2);
        ctx.fillText(this.selectCategory.top_padding,0,0);
        ctx.restore();
        // 画箭头
        ctx.moveTo(org.x+sumWidth-right+4,org.y-4);
        ctx.lineTo(org.x+sumWidth-right-4, org.y+4);
        ctx.stroke();

        //右下
        ctx.moveTo(org.x+sumWidth-right, org.y+sumHeight-bottom);
        ctx.lineTo(org.x+sumWidth-right, org.y+sumHeight);
        ctx.save();
        ctx.translate(org.x+sumWidth-right-5, org.y+sumHeight-bottom/2);
        ctx.rotate(Math.PI/2);
        ctx.fillText(this.selectCategory.bottom_padding,0,0);
        ctx.restore();
        // 画箭头
        ctx.moveTo(org.x+sumWidth-right+4, org.y+sumHeight-4);
        ctx.lineTo(org.x+sumWidth-right-4, org.y+sumHeight+4);
        ctx.stroke();
      },
      drawGrid(){
        const {canvas,ctx} = this;
        const radio =  this.innerWidth/this.selectCategory.width;  //真实长度和像素比例
        const cp = this.cpadding;
        const sumWidth = this.selectCategory.width;
        const sumHeight = this.selectCategory.height;

        const top = this.selectCategory.top_padding;
        const left = this.selectCategory.left_padding;

        const org = {x:cp[3]+this.innerLeft+left%1000*radio, y:cp[0]+this.innerTop+top%1000*radio};

        let w = sumWidth - left%1000;
        let h = sumHeight - top%1000;
        let margin = 1000*radio;
        // ctx.save();
        ctx.translate(0.5,0.5);
        ctx.strokeStyle = '#bbb';
        ctx.setLineDash([2,6]);
        //纵向线
        for(let i = 0; i <= w/1000; i++){
          ctx.moveTo(org.x+i*margin, org.y - top%1000*radio);
          ctx.lineTo(org.x+i*margin, org.y+sumHeight*radio- top%1000*radio);
        }
        //横向线
        for(let j = 0; j <= h/1000; j++){
          ctx.moveTo(org.x - left%1000*radio, org.y+j*margin);
          ctx.lineTo(org.x+sumWidth*radio-left%1000*radio, org.y+j*margin);
        }
        ctx.stroke();
        ctx.setLineDash([0]);
        // ctx.restore();
        // loadingInstance.close();
      },
      drawZoom(){
        let zoomRect = store.state.buyPopupData; //当前
        console.log(zoomRect)
        let title = zoomRect.title; //展位号
        const {ctx} = this;
        const cp = this.cpadding;
        const radio =  this.innerWidth/this.selectCategory.width;  //真实长度和像素比例
        const {rleft, rtop, rwidth, rheight} = this.rectPos;
        let zoomHeight = 1550;
        // let zoomWidth = 2320;
        let zoomWidth = this.canvas.width-800;
        let customer_brand = localStorage.getItem('customer_brand')||'';
       
        if(store.state.selectIndex*1===10){
          zoomHeight = 1400;
        }
        console.log('draw zoom')
        let widthA4 = 2840;
        ctx.beginPath();
        ctx.lineWidth=3;
        // ctx.rect()
        let width,height;
        //带背景图片
        if(this.options.bg){
          width = this.mapStyle.outer.width;
          height = this.mapStyle.outer.height;
        }else{
          width = this.mapStyle.inner.width;
          height = this.mapStyle.inner.height;
        }
        console.log(width,height)
        let zoomX = 400;
        let zoomY = height.replace('px','')/width.replace('px','')*widthA4+480;
        //画放大矩形框
        ctx.rect(zoomX,zoomY,zoomWidth,zoomHeight)
        //画指向放大框的线段    页面宽度3120
        ctx.moveTo(rleft+rwidth/2, rtop+rheight-20);
        ctx.lineTo(1560,height.replace('px','')/width.replace('px','')*widthA4+480);
        ctx.lineWidth = 5;
        ctx.strokeStyle = "#111";
        ctx.stroke();

        // const whRadio = zoomRect.width+this.boothMargin[1]+this.boothMargin[3]/zoomRect.height+this.boothMargin[0]+this.boothMargin[2]; //大于1为横向展位，小于1为纵向展位
        //zoomRect.width是真实像素，boothMargin中也是真实像素，width和height比值，包含上下左右边距
        const whRadio = (zoomRect.width+this.boothMargin[1]+this.boothMargin[3])/(zoomRect.height+this.boothMargin[0]+this.boothMargin[2]); //大于1为横向展位，小于1为纵向展位

       
        let zoomPadding = [100,250,100,250]; //上下边距和左右边距
        let zoomPaddingWdith = zoomWidth-zoomPadding[1]-zoomPadding[3]; //去掉内边距后，画布宽度
        let zoomPaddingHeight = zoomHeight-zoomPadding[0]-zoomPadding[2]; //去掉内边距后，画布高度
        let drawW,drawH; //横向/纵向展位（包含外边框）
        let rw,rh;//展位绘制的宽高px
        ctx.fillStyle="#ffadad";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        let rectLeft,rectTop; //矩形顶点位置
        let boothMarginPx = [0,0,0,0];
        if(whRadio>zoomPaddingWdith/zoomPaddingHeight){
          //横向展位（包含外边框）
          drawW = zoomPaddingWdith;
          drawH = drawW/whRadio;
          rw = zoomRect.width/(zoomRect.width+this.boothMargin[1]+this.boothMargin[3])*drawW;
          rh = zoomRect.height/(zoomRect.height+this.boothMargin[0]+this.boothMargin[2])*drawH;

          boothMarginPx[0] = this.boothMargin[0]/(zoomRect.height+this.boothMargin[0]+this.boothMargin[2])*drawH;
          boothMarginPx[1] = this.boothMargin[1]/(zoomRect.width+this.boothMargin[1]+this.boothMargin[3])*drawW;
          boothMarginPx[2] = this.boothMargin[2]/(zoomRect.height+this.boothMargin[0]+this.boothMargin[2])*drawH;
          boothMarginPx[3] = this.boothMargin[3]/(zoomRect.width+this.boothMargin[1]+this.boothMargin[3])*drawW;

          rectLeft = zoomX+zoomPadding[3];
          rectTop = zoomY+zoomPadding[0]+(zoomPaddingHeight-drawH)/2;

          ctx.fillRect(rectLeft+boothMarginPx[3],rectTop+boothMarginPx[0],rw,rh)

          let offset = 100;
          ctx.font = "bold 56px Arial";
          ctx.fillStyle = "#000";
          let text = this.splitText(customer_brand, drawW, 56);
          ctx.fillText(text, rectLeft+boothMarginPx[3]+rw/2, rectTop+boothMarginPx[0]+rh/2);
          
          ctx.font = "bold 56px Arial";
          ctx.fillStyle = "#000";
          ctx.fillText(title, rectLeft+boothMarginPx[3]+rw/2, rectTop+boothMarginPx[0]+rh/2-offset);
          ctx.font = "bold 46px Arial";
          ctx.fillStyle = "#000";
          ctx.fillText(zoomRect.width/1000+"×"+zoomRect.height/1000+"="+zoomRect.width*zoomRect.height/1000000+"㎡", rectLeft+boothMarginPx[3]+rw/2, rectTop+boothMarginPx[0]+rh/2+offset);

        }
        if(whRadio<=zoomPaddingWdith/zoomPaddingHeight){
          //纵向展位
          drawH = zoomPaddingHeight;
          drawW = drawH*whRadio;
          rw = zoomRect.width/(zoomRect.width+this.boothMargin[1]+this.boothMargin[3])*drawW;
          rh = zoomRect.height/(zoomRect.height+this.boothMargin[0]+this.boothMargin[2])*drawH;
          boothMarginPx[0] = this.boothMargin[0]/(zoomRect.height+this.boothMargin[0]+this.boothMargin[2])*drawH;
          boothMarginPx[1] = this.boothMargin[1]/(zoomRect.width+this.boothMargin[1]+this.boothMargin[3])*drawW;
          boothMarginPx[2] = this.boothMargin[2]/(zoomRect.height+this.boothMargin[0]+this.boothMargin[2])*drawH;
          boothMarginPx[3] = this.boothMargin[3]/(zoomRect.width+this.boothMargin[1]+this.boothMargin[3])*drawW;

          console.log("boothMarginPx",boothMarginPx)
          
          rectLeft = zoomX+zoomPadding[3]+(zoomPaddingWdith-drawW)/2;
          rectTop = zoomY+zoomPadding[0];

          //填充红色
          ctx.fillRect(rectLeft+boothMarginPx[3],rectTop+boothMarginPx[0],rw,rh)

          // if(boothMarginPx[3]===0){
          //   rectLeft = rectLeft+boothMarginPx[1]/2
          // }
          // if(boothMarginPx[1]===0){
          //   rectLeft = rectLeft-boothMarginPx[3]/2
          // }
          

          // ctx.rect(rectLeft+boothMarginPx[3],rectTop+boothMarginPx[0],rw,rh); //画实线线框
          //长条形展位  竖向
          if(zoomRect.width<=4000&&zoomRect.height>10000){
            ctx.font = "bold 36px Arial";
            ctx.fillStyle = "#000";
            let text = title+ "　" +zoomRect.width/1000+"×"+zoomRect.height/1000+"="+zoomRect.width*zoomRect.height/1000000;
            console.log("长条形展位  竖向")
            let maxFontSize = Math.floor(drawH/36)-text.length;
            let subtext = customer_brand.length<maxFontSize?customer_brand:customer_brand.substr(0,maxFontSize-1)+'...';
            text = title+ "　" + subtext + "　" + zoomRect.width/1000+"×"+zoomRect.height/1000+"="+zoomRect.width*zoomRect.height/1000000;
            ctx.save();
            ctx.translate( rectLeft+boothMarginPx[3]+rw/2,rectTop+boothMarginPx[0]+rh/2);
            ctx.rotate(Math.PI/180*90);
            ctx.textBaseline = 'middle';
            ctx.fillText(text,0,0);
            ctx.restore();
          }else{
            let offset = 100;
            let barndFontSize = 56;
            if(customer_brand.length>10){
              barndFontSize = 38;
            }
            // ctx.font = "bold 56px Arial";
            ctx.font = `bold ${barndFontSize}px Arial`;
            ctx.fillStyle = "#000";
            let text = this.splitText(customer_brand, drawW, barndFontSize);
            ctx.fillText(text, rectLeft+boothMarginPx[3]+rw/2, rectTop+boothMarginPx[0]+rh/2);
            ctx.font = "bold 56px Arial";
            
            ctx.fillStyle = "#000";
            ctx.fillText(title, rectLeft+boothMarginPx[3]+rw/2, rectTop+boothMarginPx[0]+rh/2-offset);
            ctx.font = "bold 46px Arial";
            ctx.fillStyle = "#000";
            ctx.fillText(zoomRect.width/1000+"×"+zoomRect.height/1000+"="+zoomRect.width*zoomRect.height/1000000+"㎡", rectLeft+boothMarginPx[3]+rw/2, rectTop+boothMarginPx[0]+rh/2+offset);
          }
        }
        ctx.lineWidth = 4;
        ctx.strokeStyle = "#111";
        ctx.stroke();

        // 根据边框类型画选款
        // if(!this.diySet){
        //   if(boothMarginPx[0]===0){
        //     this.boothBorder[0] = 0;
        //   }
        //   if(boothMarginPx[1]===0){
        //     this.boothBorder[1] = 0;
        //   }
        //   if(boothMarginPx[2]===0){
        //     this.boothBorder[2] = 0;
        //   }
        //   if(boothMarginPx[3]===0){
        //     this.boothBorder[3] = 0;
        //   }
        // }

        let dot1 = {x:rectLeft+boothMarginPx[3],y:rectTop+boothMarginPx[0]};
        let dot2 = {x:rectLeft+boothMarginPx[3]+rw,y:rectTop+boothMarginPx[0]};
        let dot3 = {x:rectLeft+boothMarginPx[3]+rw,y:rectTop+boothMarginPx[0]+rh};
        let dot4 = {x:rectLeft+boothMarginPx[3],y:rectTop+boothMarginPx[0]+rh};
        if(this.boothBorder[0]!==2){
          ctx.save(); 
          ctx.beginPath();
          ctx.moveTo(dot1.x,dot1.y);
          ctx.lineTo(dot2.x,dot2.y);
          ctx.setLineDash([0]);
          ctx.fillStyle = "#000";
          ctx.lineWidth = 8;
          if(this.boothBorder[0]===1){
            ctx.fillStyle = "#666";
            ctx.lineWidth = 4;
            ctx.setLineDash([15]);
          }
          ctx.stroke();
        }
        if(this.boothBorder[1]!==2){
          ctx.save(); 
          ctx.beginPath();
          ctx.moveTo(dot2.x,dot2.y);
          ctx.lineTo(dot3.x,dot3.y);
          ctx.setLineDash([0]);
          ctx.fillStyle = "#000";
          ctx.lineWidth = 8;
          if(this.boothBorder[1]===1){
            ctx.fillStyle = "#666";
            ctx.lineWidth = 4;
            ctx.setLineDash([15]);
          }
          ctx.stroke();
        }
        if(this.boothBorder[2]!==2){
          ctx.save(); 
          ctx.beginPath();
          ctx.moveTo(dot3.x,dot3.y);
          ctx.lineTo(dot4.x,dot4.y);
          ctx.setLineDash([0]);
          ctx.fillStyle = "#000";
          ctx.lineWidth = 8;
          if(this.boothBorder[2]===1){
            ctx.fillStyle = "#666";
            ctx.lineWidth = 4;
            ctx.setLineDash([15]);
          }
          ctx.stroke();
        }
        if(this.boothBorder[3]!==2){
          ctx.save(); 
          ctx.beginPath();
          ctx.moveTo(dot4.x,dot4.y);
          ctx.lineTo(dot1.x,dot1.y);
          ctx.setLineDash([0]);
          ctx.fillStyle = "#000";
          ctx.lineWidth = 8;
          if(this.boothBorder[3]===1){
            ctx.fillStyle = "#666";
            ctx.lineWidth = 4;
            ctx.setLineDash([15]);
          }
          ctx.stroke();
        }


        //竖向标尺(x,y,h,text,dir)
        //展位长度标尺
        this.drawZoomRule(rectLeft+boothMarginPx[3]+rw, rectTop+boothMarginPx[0], rh, zoomRect.height,"right");
        //  this.drawZoomRule(rectLeft+boothMarginPx[3]+rw,rectTop,boothMarginPx[0],this.boothMargin[0]); //右上
        this.boothMargin[0]&&this.drawZoomRule(rectLeft+boothMarginPx[3], rectTop,boothMarginPx[0], this.boothMargin[0],"left"); //左上边距标尺
        this.boothMargin[2]&&this.drawZoomRule(rectLeft+boothMarginPx[3]+rw, rectTop+boothMarginPx[0]+rh, boothMarginPx[2], this.boothMargin[2], "right"); //右下边距标尺

        //横向标尺(x,y,h,text)
        // 展位宽度标尺
        this.drawZoomRule2(rectLeft+boothMarginPx[3], rectTop+boothMarginPx[0]+rh, rw, zoomRect.width, "bottom");
        // 左下边距标尺
        boothMarginPx[3]&&this.drawZoomRule2(rectLeft, rectTop+boothMarginPx[0]+rh, boothMarginPx[3], this.boothMargin[3], "bottom");
        // 右上边距标尺
        boothMarginPx[1]&&this.drawZoomRule2(rectLeft+boothMarginPx[3]+rw, rectTop+boothMarginPx[0], boothMarginPx[1], this.boothMargin[1],"top");


        if(!this.options.bt&&!this.options.haobiao&&!this.options.key&&!this.options.en){
          let textTop = height.replace('px','')/width.replace('px','')*widthA4+500+zoomHeight+100;
          ctx.fillStyle="#111";
          ctx.font = "600 48px Arial"
          ctx.textAlign = 'left'
          let p0 = "展位设计指引：";
          let p1 = "1、 隔墙设计和搭建要求: 展位设计和搭建的隔墙和开口必须遵循展位图规划要求, 实线位置为实隔墙, 实线位置若需开口, 在不影";
          let p2 = "响相邻关系的情况下, 由参展商自行决定; 无线条位置必须全开口; 虚线位置的设计必须做成视线通透的效果, 不能阻挡视线并预留";
          let p3 = "进出口, 若因支撑作用的需要做实隔墙, 该实墙的宽度必须控制在虚线位置总长的20%以內。";
          let p4 = "2、限高要求: 正常展位限高4.5米, 超高展位限高6米 (超高展位、吊点结构、二层展位, 须加盖国家一级注册结构工程师印章)";
          let p5 = "3、跨度 封顶要求: 天花, 横梁跨度不能超过6米, 天花封顶面积不能超过展位面积1/3。";
          let p6 = "4、 材料要求; 搭建材料、 地毯及装饰材料, 必须阻燃、 难燃, 须备阻燃检测报告";
          let p7 = "5、 参展商必须在展位面向通道方向标注有清晰可见的展位号";
          let p8 = "6、 高出相邻展位的背板部分必须作美化装饰处理, 不得影响相邻展位的參展形象。";
          ctx.fillText(p0, 220, textTop);
          ctx.font = "300 48px Arial"
          textTop+=65
          ctx.fillText(p1, 220, textTop);
          textTop+=65
          ctx.fillText(p2, 220, textTop);
          textTop+=65
          ctx.fillText(p3, 220, textTop);
          textTop+=65
          ctx.fillText(p4, 220, textTop);
          textTop+=65
          ctx.fillText(p5, 220, textTop);
          textTop+=65
          ctx.fillText(p6, 220, textTop);
          textTop+=65
          ctx.fillText(p7, 220, textTop);
          textTop+=65
          ctx.fillText(p8, 220, textTop);
          textTop+=100
          ctx.fillText(moment().format('YYYY-MM-DD'), 2780, textTop);
        }

        if(!this.options.bt&&!this.options.haobiao&&!this.options.key&&this.options.en){
          let textTop = height.replace('px','')/width.replace('px','')*widthA4+500+zoomHeight+100;
          ctx.fillStyle="#111";
          ctx.font = "600 48px Arial"
          ctx.textAlign = 'left'
          let p0 = 'Booth Design Guidelines:'
          let p1 = "1、Design and construction requirements for the partition wall: when design and construct the partition wall and opening of the booth, please comply with the following requirements of booth";
          let p2 = "planning: the position of solid line is where the solid wall is. If the position of solid line needs to be opened, it shall be decided by the exhibitor on the base of not affecting the adjacent booths. ";
          let p3 = "The position of the wireless must be fully open. The position of dotted line must be transparent. It is not allowed to block the sight, and it is also required to reserve an entrance/exit in this position. ";
          let p4 = "If a solid wall is required to be built in this position to support the booth, the width of the solid wall must be controlled within 20% of the total length of the dotted line.";
          let p5 = "2、Height requirement: height of normal custom-built booth shall not exceed 4.5 meters and ultra-high booth shall not exceed 6 meters (all the drawings of ultra-high booth, lifting point structure, ";
          let p6 = "two-storey booth shall be signed and sealed by National Class 1 Registered Structural Engineer).";
          let p7 = "3、Span and capping requirement: The span of beam and ceiling beam must not exceed 6 meters, and capping of the booth cannot exceed 1/3 of total area of the booth.";
          let p8 = "4、Material requirement: construction materials, carpet and decoration materials of the booth must be non-combustible and flame retardant materials and flame retardant certification is required.";
          let p9 = "5、Booth number must be shown on exterior wall facing the aisle.";
          let p10 = "6、The part of the back panel higher than the adjacent booth must be decorated to maintain exhibition image.";
          ctx.fillText(p0, 160, textTop);
          ctx.font = "300 34px Arial"
          textTop+=65
          ctx.fillText(p1, 160, textTop);
          textTop+=65
          ctx.fillText(p2, 160, textTop);
          textTop+=65
          ctx.fillText(p3, 160, textTop);
          textTop+=65
          ctx.fillText(p4, 160, textTop);
          textTop+=65
          ctx.fillText(p5, 160, textTop);
          textTop+=65
          ctx.fillText(p6, 160, textTop);
          textTop+=65
          ctx.fillText(p7, 160, textTop);
          textTop+=65
          ctx.fillText(p8, 160, textTop);
          textTop+=65
          ctx.fillText(p9, 160, textTop);
          textTop+=65
          ctx.fillText(p10, 160, textTop);
          textTop+=60
          ctx.fillText(moment().format('MM/DD/YYYY'), 2900, textTop);
        }

        if(this.options.bt&&!this.options.en){
          let textTop = height.replace('px','')/width.replace('px','')*widthA4+500+zoomHeight+100;
          ctx.fillStyle="#111";
          ctx.font = "600 48px Arial"
          ctx.textAlign = 'left'
          let p0 = "展位设计指引：";
          let p1 = "1、展位标准如下";
          let p2 = "1）2.5米高型材围板结构；2）长臂射灯4支；3）一套楣板、公司宣传LOGO；4）阻燃展览地毯。";
          let p3 = "2、家电标配如下：";
          let p4 = "1）一套洽谈桌椅(一桌四椅)；2）一张咨询台（铝料结构）+1张吧椅；3）一个插座（5A插座）；4）一个垃圾桶";
          let p5 = "*面积为9㎡倍数的标准展位，可获得对应倍数的家具电器配置。";
          ctx.fillText(p0, 220, textTop);
          ctx.font = "300 48px Arial"
          textTop+=65
          ctx.fillText(p1, 220, textTop);
          textTop+=65
          ctx.fillText(p2, 220, textTop);
          textTop+=65
          ctx.fillText(p3, 220, textTop);
          textTop+=65
          ctx.fillText(p4, 220, textTop);
          textTop+=65
          ctx.fillText(p5, 220, textTop);
          textTop+=210
          ctx.fillText(moment().format('YYYY-MM-DD'), 2780, textTop);
        }
        if(this.options.bt&&this.options.en){
          let textTop = height.replace('px','')/width.replace('px','')*widthA4+500+zoomHeight+100;
          ctx.fillStyle="#111";
          ctx.font = "600 48px Arial"
          ctx.textAlign = 'left'
          let p0 = "Booth Design Guidelines:";
          let p1 = "1. Booth standards are as follows:";
          let p2 = "1) High profile material dividers with 2.5 meters high; 2) 4 long arm spotlights; 3) a set of fascia board and company LOGO; 4) Flame-retardant exhibition carpet.";
          let p3 = "2.Furniture package standards are as follows:";
          let p4 = "1) A set of negotiation table and chair (a table with four chairs); 2) A reception desk (aluminum structure) +a bar chair; 3) A power socket (5A socket); 4) A trash basket.";
          let p5 = "*The standard booth with an area of 9 square meters and its multiples can obtain the corresponding multiples of furniture and electrical equipment configuration.";
          ctx.fillText(p0, 200, textTop);
          ctx.font = "300 38px Arial"
          textTop+=75
          ctx.fillText(p1, 200, textTop);
          textTop+=75
          ctx.fillText(p2, 200, textTop);
          textTop+=85
          ctx.fillText(p3, 200, textTop);
          textTop+=75
          ctx.fillText(p4, 200, textTop);
          textTop+=75
          ctx.fillText(p5, 200, textTop);
          textTop+=220
          ctx.fillText(moment().format('MM/DD/YYYY'), 2900, textTop);
        }
        if(this.options.haobiao){
          let textTop = height.replace('px','')/width.replace('px','')*widthA4+500+zoomHeight+100;
          ctx.fillStyle="#111";
          ctx.font = "600 48px Arial"
          ctx.textAlign = 'left'
          let p0 = "展位设计指引：";
          let p1 = "1、展位标准如下";
          let p2 = "1）4米高木结构，可挂砖；2）长臂射灯4支；3）一套公司宣传logo楣板；4）阻燃展览地毯";
          let p3 = "2、家具标配如下：";
          let p4 = "1）一套洽谈桌椅（一桌四椅）；2）一张咨询台（铝料结构）+1张吧台椅；3）一个插座（5A插座）4）一个垃圾桶";
          let p5 = "*面积为9㎡倍数的，可获得对应倍数的家具配置。";
          ctx.fillText(p0, 220, textTop);
          ctx.font = "300 48px Arial"
          textTop+=65
          ctx.fillText(p1, 220, textTop);
          textTop+=65
          ctx.fillText(p2, 220, textTop);
          textTop+=65
          ctx.fillText(p3, 220, textTop);
          textTop+=65
          ctx.fillText(p4, 220, textTop);
          textTop+=65
          ctx.fillText(p5, 220, textTop);
          textTop+=210
          ctx.fillText(moment().format('YYYY-MM-DD'), 2780, textTop);
        }
        if(this.options.key){
          let textTop = height.replace('px','')/width.replace('px','')*widthA4+500+zoomHeight+100;
          ctx.fillStyle="#111";
          ctx.font = "600 48px Arial"
          ctx.textAlign = 'left'
          let p0 = "展位设计指引：";
          let p1 = "1.展位由主办方设计搭建";
          let p2 = "2.背墙可进行挂砖展示";
          let p3 = "3.展位内家具根据展位面积进行配套";
          ctx.fillText(p0, 220, textTop);
          ctx.font = "300 48px Arial"
          textTop+=65
          ctx.fillText(p1, 220, textTop);
          textTop+=65
          ctx.fillText(p2, 220, textTop);
          textTop+=65
          ctx.fillText(p3, 220, textTop);
          textTop+=210
          ctx.fillText(moment().format('YYYY-MM-DD'), 2780, textTop);
        }
        // this.getDist(); //获取展位之间的间距
      },
      //画放大框右侧标尺
      drawZoomRule(x,y,size,text,dir){
        const {ctx} = this;
        let offset = 30; //标尺两端线段长度
        let offset2 = 10;
        if(dir==='left'){
          offset = -30;
          offset2 = -10;
        }
        ctx.lineWidth = 1;
        ctx.setLineDash([0]);
        ctx.fillStyle = "#000";
        ctx.save(); 
        ctx.translate(0.5,0.5);
        ctx.beginPath();
           //画右侧标尺
        ctx.moveTo(x+offset,y);
        ctx.lineTo(x+offset,y+size);
        ctx.moveTo(x+offset+offset2, y);
        ctx.lineTo(x+offset2, y);
        ctx.moveTo(x+offset+offset2, y+size);
        ctx.lineTo(x+offset2, y+size);
        // 画箭头
        ctx.moveTo(x+offset-6,y-6);
        ctx.lineTo(x+offset+6,y+6);
        ctx.moveTo(x+offset-6,y+size-6);
        ctx.lineTo(x+offset+6,y+size+6);
        ctx.stroke();
        //画右侧标尺文字
        ctx.beginPath();
        ctx.font = "300 36px Arial";
        ctx.save();
        ctx.translate(x+offset+offset*0.8,y+size/2);
        ctx.rotate(Math.PI/2);
        ctx.fillText(text,0,0);
        ctx.restore();
      },
       //画放大框下方侧标尺
       drawZoomRule2(x,y,size,text,dir){
        const {ctx} = this;
        let offset = 30; //标尺两端线段长度
        let offset2 = 10;
        if(dir==='top'){
          offset=-25;
          offset2 = -10;
        }
        ctx.lineWidth = 1;
        ctx.fillStyle = "#000";
        // ctx.save(); 
        ctx.translate(0.5,0.5);
        ctx.beginPath();

        //画下方标尺
        ctx.moveTo(x,y+offset);
        ctx.lineTo(x+size, y+offset);
        ctx.moveTo(x,y+offset2);
        ctx.lineTo(x, y+offset+offset2);
        ctx.moveTo(x+size,y+offset2);
        ctx.lineTo(x+size, y+offset+offset2);
         // 画箭头
        ctx.moveTo(x+6,y+offset-6);
        ctx.lineTo(x-6,y+offset+6);
        ctx.moveTo(x+size+6, y+offset-6);
        ctx.lineTo(x+size-6, y+offset+6);
        ctx.stroke();

        //画右侧标尺文字
        ctx.beginPath();
        ctx.font = "300 36px Arial";
        ctx.fillText(text,x+size/2,y+offset+offset);
        ctx.stroke();
      },
      drawShuiyin(){
        const {ctx,canvas} = this;
        //清除画布
        // ctx.clearRect(0, 0, 160, 100);
        ctx.rotate(-20 * Math.PI / 180);
        ctx.globalAlpha = 0.1;
        ctx.fillStyle = "#999";
        ctx.textAlign = 'left';
        ctx.textBaseline = 'Middle';
        ctx.font = "50px Microsoft JhengHei";
        for (let y = -500; y < canvas.height*2; y += 320) { // 控制每次绘制的间距
          for (let x = -1400; x < canvas.width*2; x += 700) { // 控制每次绘制的列数
              ctx.fillText(this.user.name+" "+this.user.mobile, x, y); // 绘制文本 "Hello World"
            console.log(this.user.name+" "+this.user.mobile)
          }
        }
        ctx.stroke();
        ctx.rotate(0 * Math.PI / 180);
      },
      openMarginSetting(){
        this.setMarginPopupIsShow = true;
      },
      openBorderTypeSetting(){
        this.setBorderTypeIsShow = true;
      },
      setMargin(){
        if(this.boothMargin[0]===""){
          message.error("请输入上边距！");
          return false;
        }
        if(this.boothMargin[1]===""){
          message.error("请输入右边距！");
          return false;
        }
        if(this.boothMargin[2]===""){
          message.error("请输入下边距！");
          return false;
        }
        if(this.boothMargin[3]===""){
          message.error("请输入左边距！");
          return false;
        }
        this.boothMargin[0]=this.boothMargin[0]*1;
        this.boothMargin[1]=this.boothMargin[1]*1;
        this.boothMargin[2]=this.boothMargin[2]*1;
        this.boothMargin[3]=this.boothMargin[3]*1;
        console.log(this.boothMargin)
        this.setMarginPopupIsShow = false;
        this.diySet = true;
        this.initCalc();
      },
      setBorder(){
        this.setBorderPopupIsShow = false;
        this.diySet = true;
        this.initCalc();
      },
      bgSwitch(){
        this.initCalc();
      },
      rulerSwitch(){
        this.initCalc();
      },
      gridSwitch(){
        this.initCalc();
      },
      enSwitch(){
        this.initCalc();
      },
      btSwitch(){
        this.diySet = true;
        this.options.haobiao=false;
        this.options.key=false;
        this.initCalc();
      },
      haobiaoSwitch(){
        this.diySet = true;
        this.options.bt=false;
        this.options.key=false;
        this.initCalc();
      },
      keySwitch(){
        this.diySet = true;
        this.options.bt=false;
        this.options.haobiao=false;
        this.initCalc();
      },
      splitText(text, maxwidth, fontsize){
        if(text.length*fontsize<=maxwidth){
          return text;
        }
        return text.substr(0,Math.floor(maxwidth/fontsize)-1)+'...';
      },
      download(){
        const canvas = this.canvas;
        let image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"); 
    
        let link = document.getElementById('link');
        link.setAttribute('download', this.selectCategory.name+'.png');
        link.setAttribute('href', canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
        link.click();
      },
      close(){
        this.options.en = false;
        this.options.bt = false;
        this.options.haobiao = false;
        this.show = false;
        this.options.grid=false;
        this.options.bg=true;
        this.options.status=false;
        this.options.ruler=true;
        this.$emit('update:exportVisible', false)
        this.tempRectList = [];
      },
      changeToolType(type){
        this.toolType = type;
      },
    },
    watch:{
      exportVisible(val){
        this.show = val;
        this.$nextTick(()=>{
          this.boothMargin = [0,0,0,0]
          // this.boothBorder = [1,1,1,1]
          this.boothBorder[0] = store.state.buyPopupData.top_line;
          this.boothBorder[1] = store.state.buyPopupData.right_line;
          this.boothBorder[2] = store.state.buyPopupData.bottom_line;
          this.boothBorder[3] = store.state.buyPopupData.left_line;
          this.diySet = false;
          this.initCalc();
        })
      },
      selectCategory(val){
        this.$nextTick(()=>{
          // this.initCalc();
        });
      }
    }
  }
</script>

<style scoped>
.el-dialog .el-dialog__body{padding:10px 20px 30px 20px;}
.set_margin{color:#fe0000; text-decoration: underline; cursor:pointer;}
.set_margin>span:nth-of-type(1){margin-right:3px;}

.headbox{width:100%; display: flex; justify-content: center; align-items: center; margin-top:-55px;}
.headbox .item{flex:0 0 auto; line-height: 21px; margin:0 10px;}
.headbox .item>*{vertical-align: middle;}
.headbox .item.download{cursor: pointer; color:#247fce; margin:0 10px; font-weight: bold;}
.headbox .item.download:hover{color:#247fce;  text-decoration: underline;}
.export-content{border:1px dashed #ccc; margin-top:10px; display:flex; background: rgba(255,140,0,.1); user-select: none;
position: absolute; top:65px; bottom:0; right: 0; left:0; }
#exportBooth{margin:auto;}
#link{display:none;}
canvas{transition-duration: 300ms; max-width: 98%; max-height:98%;}
.dialog-footer{text-align: center;}
.export-tips{text-align: center; color:#888; margin-top:10px; font-size:12px;}
</style>